import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

const RegisterAfterLogin = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <div>
        <main className="main footer-content mt-5">
          <div className="container">
            <h2 className="sectionTitle"> Registration </h2>
            <div className="terms-content">
              <p>1. Click on the “Register” tab.</p>
              <p>2. Enter your contact number followed by OTP. </p>
              <p>3. Accurately fill in the following details:</p>

              <ul>
                <li>➔ Enter Full Name</li>
                <li>➔ Select Username</li>
                <li>➔ Select & Confirm Password</li>
                <li>➔ Enter Agent/referral code</li>
              </ul>
            </div>
          </div>
        </main>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default RegisterAfterLogin;
