import React from "react";
import { Navigate } from "react-router-dom";
import ModalWrapper from "../containers/ModalWrapper";

const ProtectedRoutes = ({ isAuth, redirectPath = "/sign-in", children }) => {
  if (!isAuth) {
    return (
      <>
        <Navigate to={"/"} state={{ showModal: true }} />
      </>
    );
  }
  return children;
};
export default ProtectedRoutes;
