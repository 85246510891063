import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

const SelfExclusions = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div>
        <main className="main footer-content mt-5">
          <div className="container">
            <h2 className="sectionTitle"> Self Exclusion </h2>
            <div className="terms-content">
              <p>
                ● You have the option to self-exclude from playing games on our
                website. To do this, you will need to log in and go to the
                self-exclusion page. For non-UK residents, setting a
                self-exclusion period will automatically reactivate your
                account.{" "}
              </p>
              <p>
                ● If you choose to self-exclude, you will not be able to open an
                account on the Website. You will also be deprived of any
                participation in any contests or sweepstakes entered prior to
                this self-exclusion and any prizes or entertainment granted or
                offered but not redeemed or used prior to this self-exclusion.{" "}
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default SelfExclusions;
